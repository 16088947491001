<template>
	<div>
		<div class="auth-component"></div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	methods: {
		...mapActions({
			saveToken: 'auth/saveToken'
		})
	},
	mounted: function() {
		if (this.$route.query.token) {
			console.log('token', this.$route.query.token);
			this.saveToken({
				token: this.$route.query.token
			}).then(async () => {
				console.log('closing the window');
				window.close();
			});
		}
	}
};
</script>

<style></style>
